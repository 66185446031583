import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Head from "../components/head"

const CONTACT = () => {
  return (
    <div>
      <Layout>
        <Head title="Contact" />
        <h2>Contact</h2>
        <p>
          I am not very active on social media, but I do have a
          <ul>
            <li>LinkedIn Profile</li>
            <li>a github account</li>
            <li>I also have an email address.</li>
          </ul>
        </p>
      </Layout>
    </div>
  )
}

export default CONTACT
